import AdvantageSection from '@/v2/components/sections/home/advantage-section'
import EvenOddSection from '@/v2/components/sections/home/even-odd-section'
import Features from '@/v2/components/sections/home/features-section'
import WhyDifferentSection from '@/v2/components/sections/home/why-different-section'
// import Promotion from '@/components/sections/Promotion'
import ReviewsSection from '@/v2/components/sections/home/reviews-section'
import StorageFeaturesSection from '@/v2/components/sections/home/storage-features-section'
import {
  advantageSectionContent,
  evenOddSectionContent,
  featuresSectionContent,
  reviewsContent,
  storageFeaturesSectionContent,
  WhyDifferentSectionContent
} from '@/v2/constants/pages/public-pages/home-page'

export default function HomePage() {
  return (
    <>
      <Features
        title={
          <h2>
            How <span>Smart Storage</span> Works
          </h2>
        }
        description={featuresSectionContent.description}
        cards={featuresSectionContent.cards}
      />

      <WhyDifferentSection
        title={
          <h2>
            We Brought <span>Customized</span> Solution
          </h2>
        }
        cards={WhyDifferentSectionContent.cards}
        ctaButton={WhyDifferentSectionContent.ctaButton}
      />

      <EvenOddSection
        title={
          <h2>
            Your One Stop Place <span>For Storage and Shipping</span> Services
          </h2>
        }
        articles={evenOddSectionContent.articles}
      />

      <AdvantageSection
        title={
          <h2>
            Ship and Storage <span>Advantage</span>
          </h2>
        }
        cards={advantageSectionContent.cards}
        ctaButton={advantageSectionContent.ctaButton}
      />

      <StorageFeaturesSection
        title={
          <h2>
            What We <span>Store</span>
          </h2>
        }
        cards={storageFeaturesSectionContent.cards}
      />

      <ReviewsSection
        title={
          <h2>
            What People Say <span>About Our Services</span>
          </h2>
        }
        reviews={reviewsContent}
      />
    </>
  )
}
