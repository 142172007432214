import Meta from '@/components/common/Meta'
import { META_CONTENTS } from '@/v1/constants/seo'
import React from 'react'
// import Promotion from '@/components/sections/Promotion'
import BookNowForm from '@/components/book-storage-facility/bookNowForm'
import HomePage from '@/v2/components/pages/public-pages/home-page'

function Home() {
  return (
    <>
      <Meta
        title={META_CONTENTS.home.title}
        keywords={META_CONTENTS.home.keywords}
        description={META_CONTENTS.home.description}
      />

      <BookNowForm />
      {/*
      <HomeBanner />

      <Features />

      <WhyDifferent />

      <CustomizedSolution />

      <StorageFeatures />

      <Reviews /> */}

      {/*<Promotion />*/}
      <HomePage />
    </>
  )
}

export default Home
